.App {
  text-align: center;
}
body {
  padding: 0px;
  margin: 0px;
  background-color: whitesmoke;
}
h1,h2,h3, p {
  font-family: Arial, Helvetica, sans-serif;
}
.unity-canvas {
  width: 100%;
  height: 100vh;
}