.footer {
    width: 100%;
    min-height: 100px;
    height: 100px;
    margin: 0;
    background: #000000;
    text-align: center;
    margin-bottom: 0px;
    padding-top: 20px;
  }
  .social-links img {
    height: 20px;
    margin: 20px;
    cursor: pointer;
  }