.header-container {
  height: 80vh;
  display: flex;
  padding: 15px;
  background-image: url(../images/space.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  flex-flow: row wrap;
}
.header-container .header-content {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 400px;
}
.header-content h1 {
  margin-top: 150px;
  margin-bottom: 0px;
  color: white;
  text-align: left;
  font-size: 42px;
}
.header-content p {
  margin-top: 15px;
  font-size: 20px;
  color: white;
}
.header-content button {
  width: 150px;
  height: 40px;
  border: none;
  border-radius: 5px;
  margin-left: -15px;
  margin-top: 15px;
  align-items: left;
  background-color: white;
  color: black;
  font-size: 16px;
}
.header-content a {
  color: white;
}

.header-content button:hover {
  cursor: pointer;
  background-color: rgb(241, 241, 241);
}
.header-content button:active {
  cursor: pointer;
  transform: scale(1);
  box-shadow: 0px 1px 2.5px rgb(180, 180, 180);
}
.header-content img {
  margin-top: 35px;
  height: 500px;
  width: 500px;
}

.network-img img {
  margin-top: 35px;
  height: 350px;
  width: 350px;
}

@media only screen and (max-width: 900px) {
  .header-container {
    margin-bottom: -0px;
  }
  .header-content h1 {
    margin-top: 70px;
    font-size: 32px;
  }
  .header-content p {
    font-size: 18px;
  }
  .header-content img {
    margin-top: 20px;
    margin-left: 15%;
    height: 300px;
    width: 300px;
  }
}
