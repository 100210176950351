.edit-cards {
  width: 100vw;
  height: 400px;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  padding-top: 50px;
  padding-bottom: 150px;
  background-color: black;
}
.edit-cards p {
  color: black;
}
.edit-cards .card {
  width: 800px;
  height: 100%;
  margin: 30px;
  padding: 15px;
  overflow: hidden;
  position: relative;
  flex-flow: row wrap;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.349);
}

.edit-cards .card .card-title {
  height: 150px;
}
.card-body {
  padding-left: 15px;
}
.card-body button {
  width: 80%;
}
.card-body .explore-btn {
  background-color: black;
  border-color: black;
  border-style: solid;
  color: white;
  margin-left: -15px;
}
.card-body .explore-btn:hover {
  background-color: black;
  border-color: black;
  border-style: solid;
  color: white;
}
.card-title p {
  margin-top: 0px;
  color: black;
  font-size: medium;
}
.edit-cards .card .card-body {
  width: 50%;
}
.edit-cards h1 {
  color: lightgray;
  display: flex;
  justify-content: left;
  width: 100%;
  font-size: medium;
  font-weight: lighter;
}
.edit-cards h2 {
  color: black;
  display: flex;
  justify-content: left;
  width: 100%;
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: xx-large;
}
.edit-cards h3 {
  color: black;
  display: flex;
  justify-content: left;
  width: 100%;
  margin-top: 0px;
  font-size: x-large;
  font-weight: lighter;
}
.edit-cards button {
  background-color: black;
  color: white;
  border: none;
  border-radius: 5px;
  height: 50px;
  width: 95%;
  padding-left: -15px;
  font-size: large;
  margin-top: 10px;
  cursor: pointer;
}

.card .card-art {
  height: 100%;
}
.card .card-art img {
  display: flex;
  justify-content: center;
  height: 400px;
  width: 400px;
}
.card-body button:hover {
  background-color: black;
  color: white;
  border: none;
  height: 50px;
  opacity: 0.8;
}

@media only screen and (max-width: 1000px) {
  .edit-cards {
    width: 100vw;
    height: 100%;
    justify-content: center;
    padding-top: 50px;
    padding-bottom: 150px;
    background-color: black;
  }
  .edit-cards p {
    color: black;
  }
  .edit-cards .card {
    width: 300px;
    margin-bottom: -50px;
    padding: 15px;
    overflow: hidden;
    position: relative;
    border-radius: 5px;
    display: flex;
    display: grid;
    background-color: white;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.349);
  }
  .card .card-art img {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    height: 300px;
    width: 300px;
    position: relative;
    flex-direction: column;
  }
  .edit-cards .card .card-title {
    width: 100%;
    height: 100%;
    
    padding-left: 0px;
  }
  .edit-cards .card .card-body {
    width: 100%;
    height: 100%;
    
    padding-left: 0px;
  }
  .edit-cards .card .card-title {
    
  }
  .card-body .card-title h1 {
    font-size: medium;
    margin-top: 15px;
  }
  .card-body h1 {
    font-size: medium;
    margin-top: 15px;
  }
  .card-body h2 {
    font-size: x-large;
  }
  .card-body h3 {
    font-size: medium;
  }

  .edit-cards {
    display: grid;
  }
}
