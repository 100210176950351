
.page .artists {
  display: flex;
  margin-top: 100px;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-around;
  height: max-content;
  flex-flow: row wrap;
}

.page .header-container {
  height: 100%;
  padding-bottom: 150px;
}

.page .header-container .network-img {
  display: flex;

}