/* This is the styling for the nav container */
.navbar {
  width: 100vw;
  height: 90px;
  font-family: Arial, Helvetica, sans-serif;
  background-color: black;
  display: flex;
  flex-direction: column;
}
/* This is the styling for the nav content */

.navbar-content {
  padding-top: 25px;
  width: 100%;
  height: 80px;
  align-items: center;
  display: flex;
}
.navbar-content button {
  border-radius: 0px;
}
.nav-links {
  padding-top: 10px;
}

/* This is the styling for the left nav container */

.left-nav {
  color: white;
  flex: 30%;
  display: flex;
  align-items: center;
  padding-left: 5%;
}

.left-nav img {
  width: 70px;
  padding-bottom: 15px;
}
/* This is the styling for the right nav container */

.right-nav {
  color: white;
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
  padding-bottom: 15px;
}

a {
  color: white;;
  font-size: large;
  text-decoration: none;
  font-family: Arial, Helvetica, sans-serif;
  margin: 15px;
}
